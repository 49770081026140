import {useCallback, useState} from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import styled from 'styled-components';

import Hide from '@/assets/icons_refactor/Client/register-hidden-eye.svg';
import Show from '@/assets/icons_refactor/Client/register-open-eye.svg';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';

interface Props {
  handleChangeValue: (value: string | boolean, name: string) => void;
  password: string;
  errorMessages: Record<string, any>;
  disabled: boolean;
  placeholder: string;
}

const PasswordField = ({
  password,
  handleChangeValue,
  errorMessages,
  placeholder = 'Password',
  disabled = false,
}: Props) => {
  const [showPassword, setShowPasword] = useState<boolean>(false);
  const handleClickShowPassword = useCallback(() => setShowPasword((prevState) => !prevState), []);

  return (
    <Wrapper variant="outlined" fullWidth error={!!errorMessages?.pwd?.length}>
      <Label>{placeholder}</Label>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        value={password}
        inputProps={{'data-qa-auto': 'password'}}
        name="password"
        label=""
        onChange={({target: {value}}) => handleChangeValue(value, 'password')}
        notched={false}
        disabled={disabled}
        endAdornment={
          <ShowHideButton disableRipple onClick={handleClickShowPassword}>
            <Icon icon={showPassword ? Hide : Show} boxW={20} boxH={20} />
          </ShowHideButton>
        }
        sx={{'& .MuiInputLabel-root': {width: '80px'}}}
        aria-describedby="password-error-text"
      />
      {!!errorMessages?.pwd?.length && <FormHelperText id="password-error-text">{errorMessages?.pwd}</FormHelperText>}
    </Wrapper>
  );
};

const Wrapper = styled(FormControl)`
  &.MuiFormControl-root {
    margin-bottom: 0.9rem;
  }
`;

const Label = styled(InputLabel)`
  background-color: ${({theme}) => theme.colors.grey000};
`;

const ShowHideButton = styled(BaseButton)`
  display: flex;
  justify-content: flex-end;
  min-width: 38px;

  &:hover {
    background-color: transparent;
  }
`;

const Icon = styled(BaseIcon)`
  fill: rgb(204, 204, 204);
  width: 20px;
  height: 20px;
`;

export default PasswordField;
